import "./App.css";
import Start from "./components/Start";
import { CustomerThemeProvider } from "./contexts/CustomerThemeContext";

function App() {
  if (
    !window.mentoPanelId ||
    !(window.mentoOrganizationName || window.mentoOrganisationName)
  ) {
    return null;
  }

  return (
    <CustomerThemeProvider panelId={window.mentoPanelId}>
      <Start
        userId={window.mentoUserId}
        panelId={window.mentoPanelId}
        customerName={
          window.mentoOrganizationName || window.mentoOrganisationName
        }
        secretHash={window.mentoSecretHash}
      ></Start>
    </CustomerThemeProvider>
  );
}

export default App;
