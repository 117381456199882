// Define color variables
const COLORS = {
  PRIMARY: "#1976D2",
  MENTO_BG: "#223C8E",
  TEXT_GRAY: "#666",
  BOX_GRAY: "#f0f0f0",
  HAPPY: "#32CD32",
  ANGRY: "#FF4500",
  SAD: "#6495ED",
  THOUGHTFUL: "#9370DB",
  UNKNOWN: "#708090",
  CLOSE_ICON: "#ff5c5c",
  SELECTED_TEXT: "#696969",
  DEFAULT_TEXT: "black",
  BUTTON_TEXT: "#fff",
};

// Export individual colors for use in components
export const PRIMARY_COLOR = COLORS.PRIMARY;
export const MENTO_BG_COLOR = COLORS.MENTO_BG;
export const TEXT_GRAY = COLORS.TEXT_GRAY;
export const BOX_GRAY = COLORS.BOX_GRAY;
export const HAPPY_COLOR = COLORS.HAPPY;
export const ANGRY_COLOR = COLORS.ANGRY;
export const SAD_COLOR = COLORS.SAD;
export const THOUGHTFUL_COLOR = COLORS.THOUGHTFUL;
export const UNKNOWN_COLOR = COLORS.UNKNOWN;
export const BUTTON_TEXT = COLORS.BUTTON_TEXT;
export const SELECTED_TEXT = COLORS.SELECTED_TEXT;
export const CLOSE_ICON = COLORS.CLOSE_ICON;

// Feelings items with labels and values
export const feelingsItems = [
  { label: "Arg", value: "ANGRY" },
  { label: "Ledsen", value: "SAD" },
  { label: "Glad", value: "HAPPY" },
  { label: "Fundersam", value: "THOUGHTFUL" },
  { label: "Osäker/Vill inte ange", value: "UNKNOWN" },
];

// Function to get label from value
export const getFeelingLabelFromValue = (value) => {
  return feelingsItems.find((f) => f.value === value).label;
};

// Function to get color based on feeling
export const getFeelingColor = (feeling) => {
  switch (feeling) {
    case "HAPPY":
      return COLORS.HAPPY;
    case "ANGRY":
      return COLORS.ANGRY;
    case "SAD":
      return COLORS.SAD;
    case "THOUGHTFUL":
      return COLORS.THOUGHTFUL;
    case "UNKNOWN":
      return COLORS.UNKNOWN;
    default:
      return COLORS.PRIMARY;
  }
};
