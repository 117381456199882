import dayjs from "dayjs";
import "dayjs/locale/sv";
import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { CLOSE_ICON, PRIMARY_COLOR, TEXT_GRAY } from "../utils/theme";
import { AnswerQuestion } from "./AnswerQuestion";
import { FeelingResults } from "./FeelingResults";
import { MoreQuestions } from "./MoreQuestions";
import { Results } from "./Results";

dayjs.locale("sv");

export const MainContentBoxExpanded = ({
  question,
  user,
  userAnswerOptionId,
  panelId,
  refreshQuestion,
  handleCloseClick,
  handleNewActiveQuestion,
  toggleWindowSize,
  webpageId,
}) => {
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [filterOnAnswerOption, setFilterOnAnswerOption] = useState(null);
  const theme = useCustomerTheme();

  useEffect(() => {
    const mostAnsweredAnswerOption = question.answer_options.sort(
      (a, b) => (b.answers[0]?.count ?? 0) - (a.answers[0]?.count ?? 0)
    )[0];
    setFilterOnAnswerOption(mostAnsweredAnswerOption);
  }, [question]);

  useEffect(() => {
    setSelectedAnswerOption(userAnswerOptionId);
  }, [userAnswerOptionId]);

  const isPastVoteEndDate =
    question.vote_end_date && new Date(question.vote_end_date) < new Date();

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "8px", right: "8px" }}>
        <div
          onClick={handleCloseClick}
          style={{
            cursor: "pointer",
            height: "16px",
            width: "16px",
            color: CLOSE_ICON,
          }}
        >
          <MdOutlineClose />
        </div>
      </div>
      <div style={{ marginBottom: "16px", paddingTop: "16px" }}>
        <span
          style={{
            fontSize: "large",
            fontWeight: "bold",
            color: theme.accent_color || PRIMARY_COLOR,
          }}
        >
          {question.title}
        </span>
        <p style={{ fontSize: "medium", color: TEXT_GRAY }}>
          {question.description}
        </p>
        {isPastVoteEndDate && (
          <p
            style={{
              fontSize: "small",
              fontStyle: "italic",
              color: TEXT_GRAY,
            }}
          >
            Tiden för att svara på denna fråga löpte ut{" "}
            {dayjs(new Date(question.vote_end_date)).format(
              "DD MMMM YYYY HH:mm"
            )}
          </p>
        )}
      </div>
      {selectedAnswerOption || isPastVoteEndDate ? (
        <div>
          <Results
            question={question}
            answerOptionId={selectedAnswerOption}
            filterOnAnswerOptionId={
              filterOnAnswerOption ? filterOnAnswerOption.id : null
            }
            onAnswerOptionClicked={(aoId) => {
              if (filterOnAnswerOption && filterOnAnswerOption.id === aoId) {
                setFilterOnAnswerOption(null);
              } else {
                const answerOption = question.answer_options.find(
                  (ao) => ao.id === aoId
                );
                setFilterOnAnswerOption(answerOption);
              }
            }}
          />
          <FeelingResults
            question={question}
            filterOnAnswerOption={filterOnAnswerOption}
          />
          <MoreQuestions
            handleNewActiveQuestion={handleNewActiveQuestion}
            question={question}
            externalUserId={user ? user?.id : undefined}
            panelId={panelId ? panelId : undefined}
          />
        </div>
      ) : (
        <AnswerQuestion
          user={user}
          onClick={(ao) => setSelectedAnswerOption(ao)}
          question={question}
          handleAnswer={refreshQuestion}
          webpageId={webpageId}
        />
      )}
    </div>
  );
};
