import { supabase } from "../utils/Api";

export const fetchUserAnswer = async (questionId, externalUserId) => {
  const { data, error } = await supabase
    .from("answers")
    .select(
      "id,question_id,answer_option_id,external_users!inner(id,external_id)"
    )
    .eq("external_users.external_id", externalUserId)
    .eq("question_id", questionId);

  if (error) {
    return null;
  } else {
    return data?.length > 0 ? data[0].answer_option_id : null;
  }
};

export const fetchUser = async (userId, secretHash, customerName, panelId) => {
  try {
    const handleUserParams = {
      input_external_id: userId,
      input_external_id_hash: secretHash,
      input_communication_identifier: customerName,
      input_panel_id: panelId,
    };

    let handleUserRes = await supabase.rpc(
      "handle_external_user",
      handleUserParams
    );

    if (
      handleUserRes.status === 200 &&
      handleUserRes.data.length > 0 &&
      !!handleUserRes.data[0]?.id
    ) {
      return handleUserRes.data[0];
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchQuestion = async (url, id, panelId) => {
  try {
    let query = supabase.from("webpages").select(
      `
        id,
        question_webpage_relationships!inner(
          questions!inner(
            title,
            published_date,
            description,
            id,
            path,
            link_url,
            vote_end_date,
            panel_id,
            panels(id, allow_anonymous_answers),
            answers(count),
            answer_options!question_id(id, answer_title, answer_option_order, image_url, answers(count))
          )
        )
      `,
      { count: "exact" }
    );

    if (id) {
      query = supabase.from("questions").select(
        `
          title,
          published_date,
          description,
          id,
          path,
          link_url,
          vote_end_date,
          question_webpage_relationships(id,webpages(id,link_url)),
          panel_id,
          panels(id, allow_anonymous_answers),
          answers(count),
          answer_options!question_id(id, answer_title, image_url, answers(count), answer_option_order)
        `,
        { count: "exact" }
      );
      query.eq("id", id);
    } else if (url) {
      const parsedUrl = new URL(url);
      const formattedUrl = parsedUrl.hostname + parsedUrl.pathname;
      query.like("link_url", `%${formattedUrl}`);
    }
    if (panelId) {
      if (id) {
        query.eq("panel_id", panelId);
      } else {
        query.eq("question_webpage_relationships.questions.panel_id", panelId);
      }
    }

    const { data, statusText, error } = await query;

    if (data && data.length > 0) {
      if (id) {
        return { question: data[0] };
      } else if (
        data[0].question_webpage_relationships.length > 0 &&
        data[0].question_webpage_relationships[0].questions
      ) {
        return {
          question: data[0].question_webpage_relationships[0].questions,
          webpageId: data[0].id,
        };
      } else {
        return { question: null, webpageId: null };
      }
    } else {
      return { question: null, webpageId: null };
    }
  } catch (e) {
    console.log(e);
    return { question: null, webpageId: null };
  }
};

export const fetchPanelPluginSettings = async (panelId) => {
  const { data, error } = await supabase
    .from("panel_plugin_settings")
    .select(
      "id,panel_id,button_image_url,button_background_color,accent_color,position,border_radius,display_mode,display_after_seconds,display_after_scroll_percentage"
    )
    .eq("panel_id", panelId);

  if (error) {
    return null;
  } else {
    return data?.length > 0 ? data[0] : null;
  }
};
