import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { getFeelingColor, PRIMARY_COLOR } from "../../utils/theme";

export const Button = ({ onClick, href, text, type }) => {
  const theme = useCustomerTheme();
  let color = theme.accent_color || PRIMARY_COLOR;
  if (type) {
    color = getFeelingColor(type);
  }

  return href ? (
    <a
      href={href}
      onClick={onClick ? onClick : () => null}
      style={{
        borderRadius: `${theme.border_radius}px` || "8px",
        borderWidth: "1px",
        borderColor: color,
        backgroundColor: "white",
        color: color,
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        textWrap: "pretty",
      }}
      target="_blank"
    >
      {text}
    </a>
  ) : (
    <button
      onClick={onClick}
      style={{
        borderRadius: `${theme.border_radius}px` || "8px",
        borderWidth: "1px",
        borderColor: color,
        backgroundColor: "white",
        color: color,
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "auto",
        justifyContent: "center",
        width: "100%",
        textWrap: "pretty",
      }}
    >
      {text}
    </button>
  );
};
