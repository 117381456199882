import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { MENTO_BG_COLOR } from "../../utils/theme";
import { iconSize } from "../Start";

const buttonBackground = window.mentoCustomPluginBackground
  ? window.mentoCustomPluginBackground
  : MENTO_BG_COLOR;
const imageBackground = window.mentoCustomPluginImage
  ? window.mentoCustomPluginImage
  : "https://gyrstiqzamltthbjartl.supabase.co/storage/v1/object/public/website/widget-icon.png";

export const PluginButton = ({ click, active }) => {
  const theme = useCustomerTheme();
  return (
    <div
      onClick={click}
      style={{
        backgroundColor: theme.button_background_color || buttonBackground,
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        borderRadius: "50px",
      }}
    >
      <FadeIn
        style={{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
          borderRadius: "50px",
        }}
        delay={50}
        visible={!active}
      >
        <img
          src={theme.button_image_url || imageBackground}
          alt="Widget Icon"
          style={{
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            borderRadius: "50px",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </FadeIn>
      <FadeIn
        style={{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
          borderRadius: "50px",
        }}
        delay={50}
        visible={active}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: "relative",
            top: "-50px",
          }}
        >
          <span
            style={{ color: "white", textAlign: "center", cursor: "pointer" }}
          >
            Stäng
          </span>
        </div>
      </FadeIn>
    </div>
  );
};
